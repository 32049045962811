import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { composeRestrictions } from 'VerificationZone/selectors/restrictions';
import Links from './links';
import topLinks, {
  fullyRestrictedLinks,
  restrictedDepositLinks,
  restrictedWithdrawLinks,
} from '../constants/top_links';

const mapStateToProps = (state) => ({
  restrictions: composeRestrictions(state),
});

const TopLinks = (props) => {
  const { restrictions } = props;

  let links = topLinks;

  if (restrictions?.length) {
    if (!restrictions[1].canPerformAction && !restrictions[0].canPerformAction) {
      links = fullyRestrictedLinks;
    } else if (!restrictions[0].canPerformAction) {
      links = restrictedWithdrawLinks;
    } else if (!restrictions[1].canPerformAction) {
      links = restrictedDepositLinks;
    }
  }

  return <Links className="bvs-card acc-overview__flex acc-overview__top-links" links={links} />;
};

TopLinks.propTypes = {
  restrictions: PropTypes.arrayOf(
    PropTypes.shape({
      canPerformAction: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};

export default connect(mapStateToProps)(TopLinks);
