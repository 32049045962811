export default [
  {
    tKey: 'withdraw',
    icon: 'withdraw',
    href: '/bv_cashier/withdraw',
    count: {
      from: 'pendingWithdrawalsCount',
      tKey: 'deposit_pending_withdrawal',
    },
  },
  {
    tKey: 'manage_payments',
    icon: 'payments',
    href: '/bv_cashier/payments',
  },
  {
    tKey: 'deposit_now',
    icon: 'deposit',
    href: '/bv_cashier/deposit',
  },
];

export const restrictedDepositLinks = [
  {
    tKey: 'withdraw',
    icon: 'withdraw',
    href: '/bv_cashier/withdraw',
    count: {
      from: 'pendingWithdrawalsCount',
      tKey: 'deposit_pending_withdrawal',
    },
  },
  {
    tKey: 'manage_payments',
    icon: 'payments',
    href: '/bv_cashier/payments',
  },
  {
    tKey: 'deposit_now',
    icon: 'deposit',
    href: '/account/verification_zone',
  },
];

export const restrictedWithdrawLinks = [
  {
    tKey: 'withdraw',
    icon: 'withdraw',
    href: '/account/verification_zone',
    count: {
      from: 'pendingWithdrawalsCount',
      tKey: 'deposit_pending_withdrawal',
    },
  },
  {
    tKey: 'manage_payments',
    icon: 'payments',
    href: '/bv_cashier/payments',
  },
  {
    tKey: 'deposit_now',
    icon: 'deposit',
    href: '/bv_cashier/deposit',
  },
];

export const fullyRestrictedLinks = [
  {
    tKey: 'withdraw',
    icon: 'withdraw',
    href: '/account/verification_zone',
    count: {
      from: 'pendingWithdrawalsCount',
      tKey: 'deposit_pending_withdrawal',
    },
  },
  {
    tKey: 'manage_payments',
    icon: 'payments',
    href: '/bv_cashier/payments',
  },
  {
    tKey: 'deposit_now',
    icon: 'deposit',
    href: '/account/verification_zone',
  },
];
